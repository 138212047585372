/** @format */

import { Redirect } from "@roadflex/react-components";
import { useReadCurrentUserAuth } from "@roadflex/react-hooks";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;
export function Index() {
  const { readCurrentUserAuth, loading } = useReadCurrentUserAuth();

  if (loading) {
    return <></>;
  }

  if (
    !readCurrentUserAuth ||
    readCurrentUserAuth.fuelCard?.isFuelCard === false
  ) {
    return <Redirect path={`${NEXT_PUBLIC_WWW_URL}/signin`}></Redirect>;
  }
  if (readCurrentUserAuth.fuelCard?.status === "ONBOARD") {
    return <Redirect path="/onboard"></Redirect>;
  }

  if (
    readCurrentUserAuth.fuelCard?.status === "IN_VERIFICATION" ||
    readCurrentUserAuth.fuelCard?.status === "REJECTED" ||
    readCurrentUserAuth.fuelCard?.status === "ON_HOLD" ||
    readCurrentUserAuth.fuelCard?.status === "CLOSED"
  ) {
    return <Redirect path="/no-access"></Redirect>;
  }
  if (readCurrentUserAuth.fuelCard?.status === "VERIFIED_WITHOUT_DEPOSIT") {
    return <Redirect path="/deposit"></Redirect>;
  }
  if (readCurrentUserAuth?.isParentCompanyUser === true) {
    return <Redirect path="/parent-company-user"></Redirect>;
  }
  return <Redirect path="/dashboard"></Redirect>;
}

export default Index;
